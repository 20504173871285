import React from 'react';
import { Section } from './elements'

const AboutSection = () => {
  return (
    <Section id='about'>
    </Section>
  )
}

export default AboutSection
