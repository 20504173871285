import React from 'react'
import { Section } from './elements';

const DownloadsSection = () => {
  return (
    <Section id='downloads'>

    </Section>
  )
}

export default DownloadsSection
